import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import "./styles.scss";
import CardsMob from "./CardMob";

const index = () => {
  const openYoutube = () => {
    window.open("https://youtu.be/cQthax_K8Lg", "_blank");
  };

  return (
    <div className="wwu-career">
      <img src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm46n8zny.png" loading="lazy" className="wwu-career-lft-dots" />
      <img src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm46nak2h.png" loading="lazy" className="wwu-career-rt-dots" />
      <div className="wwu-sub-title">Kraftshala Careers</div>
      <h2 className="wwu-title">  Prepare for <br className="pc-none"/>your Interview </h2>
      <StaticImage
        className="wwu-select-undeline"
        src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y2jyfm2opdx7v.png"
      />
      <div className="wwu-desc">
        We will focus on a range of technical and competency-based questions to
        learn more about your experience, values, and motivations to join us on
        our journey. 
      </div>

      <CardsMob />

      <div className="interviewVideoSec">
        <div className="i-v-text">Varun takes regular sessions on <span className="i-v-span">‘How to be Unbeatable at Interviews’</span>. Feel free to watch it. It is super insightful and will help with your preparation.</div>
        <button className="wwu-btn" onClick={openYoutube}>
            Watch Now &gt;&gt;
        </button>
      </div>
    </div>
  );
};

export default index;
