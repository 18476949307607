import React, { useState } from "react";
import "./styles.scss";
import { StaticImage } from "gatsby-plugin-image";
import { values } from "./data";

const index = () => {
  const [selected, setSelected] = useState(0);

  return (
    <div className="wwu-KSKode">
      <div className="wwu-title">  What We Value - The Kraftshala Kode</div>
      <img src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm46ncmpr.png" className="kodeleftDot" loading="lazy" />
      <img src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm46nefj9.png" className="koderightDot" loading="lazy" />

      <StaticImage
        className="wwu-kode-undeline"
        src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y2jyfm2opdx7v.png"
      />
      <div className="wwu-desc">
        Team Kraftshala is for the{" "}
        <b>forever learners and for those who live to add value</b> and create a
        positive impact in the lives of others. In a world where scale generally
        equates to mediocrity, our goal is to <b>Scale with Soul</b>, to offer{" "}
        <b>greater value to each stakeholder every day we get bigger</b>.
      </div>
      <div className="wwu-kode-cards">
        <div className="kode-main-container">
          {selected === 0 && (
            <div
              className="kode-main-container-text"
              style={{ maxWidth: "292px" }}
            >
              We seek <img src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm46uibh9.png" className="kode-circle0" />
              <span className="high"> user centricity</span> in our team. People
              who demonstrate a capacity to understand where our users come
              from, and use that information to act in ways that add most value
              to them, fit right in. We don’t let processes or hierarchies get
              in the way of adding value.
            </div>
          )}
          {selected === 1 && (
            <div
              className="kode-main-container-text"
              style={{ maxWidth: "299px" }}
            >
              We believe speed matters enormously in business. We value people
              who have a <img src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm46uibh9.png" className="kode-circle1" />{" "}
              <span className="high"> bias for action</span> and are willing to
              take calculated risks in the face of uncertainty.
            </div>
          )}
          {selected === 2 && (
            <div
              className="kode-main-container-text"
              style={{ maxWidth: "316px" }}
            >
              We value <img src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm46uibh9.png" className="kode-circle2" />
              <span className="high">problem solving</span> skills. We look at
              problems objectively, solve for root causes, make decisions for
              long term good instead of short term gain.
            </div>
          )}
          {selected === 3 && (
            <div
              className="kode-main-container-text"
              style={{ maxWidth: "302px" }}
            >
              We are a team of <img src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm46uibh9.png" className="kode-circle3" />
              <span className="high"> learners </span> who like to observe,
              reflect, and seek consistent feedback and knowledge to be better
              today than we were yesterday. To grow fast means we need to
              constantly make better mistakes.
            </div>
          )}
          {selected === 4 && (
            <div
              className="kode-main-container-text"
              style={{ maxWidth: "293px" }}
            >
              We admire people with
              <br /> <img src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm46uibh9.png" className="kode-circle4" />
              <span className="high">ambition & courage </span> to take on large
              goals. We are seeking to build a team of self-starters who
              demonstrate high ownership.
            </div>
          )}
          {selected === 5 && (
            <div
              className="kode-main-container-text"
              style={{ maxWidth: "369px" }}
            >
              We aim for <img src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm46uibh9.png" className="kode-circle5" />
              <span className="high"> consistency & reliability</span> and
              expect the same from others. Predictability just makes everyone’s
              life a lot smoother.
            </div>
          )}
          {selected === 6 && (
            <div
              className="kode-main-container-text"
              style={{ maxWidth: "298px" }}
            >
              We like people who demonstrate candour with care: real, open and
              direct in
              <img src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm46uibh9.png" className="kode-circle2" />
              <span className="high"> communication,</span> while still being
              highly <img src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm46uibh9.png" className="kode-circle6" />
              <span className="high"> empathetic.</span>
            </div>
          )}
          {selected === 7 && (
            <div
              className="kode-main-container-text"
              style={{ maxWidth: "298px" }}
            >
              We are <img src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm46uibh9.png" className="kode-circle4" />
              <span className="high"> practical optimists.</span> Our team
              strongly believes in a better future and takes joy and pride in
              the fact that we are playing our part to make that future a
              reality.
            </div>
          )}
        </div>
        <div className="kode-side-cards">
          {values.map((value, index) => {
            // Added index in the map callback
            return (
              <div
                className="wwu-kode-card"
                key={index} // Added key prop
                onClick={() => setSelected(index)}
                style={{ background: `url(${value.bgImg})` }}
              >
                <div
                  className={`wwu-kode-card-txt ${
                    index !== selected ? "default-color" : "selected-color"
                  }`}
                  //style={{ color: index !== selected ? "#505050" : "#f1ae13" }}
                  dangerouslySetInnerHTML={{ __html: value.title }}
                />
                {index !== selected ? (
                  <img src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm46ms0al.png" className="plusImg" />
                ) : (
                  <img src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm46mqoft.png" className="plusImg" />
                )}
              </div>
            );
          })}
        </div>
      </div>
      <div className="wwu-kode-cards-mob">
        <div className="kode-side-cards">
          {values.map((value, index) => {
            // Added index in the map callback
            return (
              <div className="kode-cover">
                <div
                  className="wwu-kode-card"
                  key={index} // Added key prop
                  onClick={() => setSelected(index)}
                  style={{ background: `url(${value.bgImg})` }}
                >
                  <div
                    className={`wwu-kode-card-txt ${
                      index !== selected ? "default-color" : "selected-color"
                    }`}
                    //style={{ color: index !== selected ? "#505050" : "#f1ae13" }}
                    dangerouslySetInnerHTML={{ __html: value.title }}
                  />
                  {index !== selected ? (
                    <img src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm46ms0al.png" className="plusImg" />
                  ) : (
                    <img src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm46mqoft.png" className="plusImg" />
                  )}
                </div>
                {index === selected && selected === 0 && (
                  <div className="kode-main-container">
                    <div
                      className="kode-main-container-text"
                      style={{ maxWidth: "292px" }}
                    >
                      We seek <img src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm46uibh9.png" className="kode-circle0" />
                      <span className="high"> user centricity</span> in our
                      team. People who demonstrate a capacity to understand
                      where our users come from, and use that information to act
                      in ways that add most value to them, fit right in. We
                      don’t let processes or hierarchies get in the way of
                      adding value.
                    </div>
                  </div>
                )}
                {index === selected && selected === 1 && (
                  <div className="kode-main-container">
                    <div
                      className="kode-main-container-text"
                      style={{ maxWidth: "299px" }}
                    >
                      We believe speed matters enormously in business. We <br/>value
                      people who have a{" "}<br/>
                      <img src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm46uibh9.png" className="kode-circle0" />{" "}
                      <span className="high"> bias for action</span> and are
                      willing to take calculated risks in the face of
                      uncertainty.
                    </div>
                  </div>
                )}
                {index === selected && selected === 2 && (
                  <div className="kode-main-container">
                    <div
                      className="kode-main-container-text"
                      style={{ maxWidth: "316px" }}
                    >
                      We value <img src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm46uibh9.png" className="kode-circle2" />
                      <span className="high">problem solving</span> skills. We
                      look at problems objectively, solve for root causes, make
                      decisions for long term good instead of short term gain.
                    </div>
                  </div>
                )}
                {index === selected && selected === 3 && (
                  <div className="kode-main-container">
                    <div
                      className="kode-main-container-text"
                      style={{ maxWidth: "302px" }}
                    >
                      We are a team of{" "}
                      <img src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm46uibh9.png" className="kode-circle3" />
                      <span className="high"> learners </span> who like to
                      observe, reflect, and seek consistent feedback and
                      knowledge to be better today than we were yesterday. To
                      grow fast means we need to constantly make better
                      mistakes.
                    </div>
                  </div>
                )}
                {index === selected && selected === 4 && (
                  <div className="kode-main-container">
                    <div
                      className="kode-main-container-text"
                      style={{ maxWidth: "293px" }}
                    >
                      We admire people with
                      <br /> <img src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm46uibh9.png" className="kode-circle4" />
                      <span className="high">ambition & courage </span> to take
                      on large goals. We are seeking to build a team of
                      self-starters who demonstrate high ownership.
                    </div>
                  </div>
                )}
                {index === selected && selected === 5 && (
                  <div className="kode-main-container">
                    <div
                      className="kode-main-container-text"
                      style={{ maxWidth: "369px" }}
                    >
                      We aim for{" "}<br/>
                      <img src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm46uibh9.png" className="kode-circle5" />
                      <span className="high">
                        {" "}
                        consistency & reliability
                      </span>{" "}
                      and expect the same from others. Predictability just makes
                      everyone’s life a lot smoother.
                    </div>
                  </div>
                )}
                {index === selected && selected === 6 && (
                  <div className="kode-main-container">
                    <div
                      className="kode-main-container-text"
                      style={{ maxWidth: "298px" }}
                    >
                      We like people who demonstrate candour with care: real,
                      open and direct in
                      <img src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm46uibh9.png" className="kode-circle2" />
                      <span className="high"> communication,</span> while still
                      being highly{" "}
                      <img src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm46uibh9.png" className="kode-circle6" />
                      <span className="high"> empathetic.</span>
                    </div>
                  </div>
                )}
                {index === selected && selected === 7 && (
                  <div className="kode-main-container">
                    <div
                      className="kode-main-container-text"
                      style={{ maxWidth: "298px" }}
                    >
                      We are <img src="https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm46uibh9.png" className="kode-circle4" />
                      <span className="high"> practical optimists.</span> Our
                      team strongly believes in a better future and takes joy
                      and pride in the fact that we are playing our part to make
                      that future a reality.
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default index;
