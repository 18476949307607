import { StaticImage } from "gatsby-plugin-image";
import "./styles.scss";
import React, { useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";

const index = () => {
  const { ref, inView } = useInView({
    triggerOnce: true,
  });
  const [muted, setMuted] = useState(true);
  const [playingVideoIndex, setPlayingVideoIndex] = useState(null);
  const [currentSlide, setCurrentSlide] = useState(-1);
  const [finalSlide, setfinalSlide] = useState(false);
  const [videoPlaying,setVideoPlaying] = useState(false);

  const handleMouseEnter = (index) => {
    setPlayingVideoIndex(index);
    const video = document.querySelectorAll('.hover-video')[index];

    if (video) {
      video.currentTime = 0; // Reset video time
      video.play(); // Play the video
    }
  };

  const handleMouseLeave = (index, video) => {
    video.pause();
    video.currentTime = 0;
    setPlayingVideoIndex(null);
    setMuted(true);
  };

  
  const handleClick = (index) => {
    const video = document.querySelectorAll('.hover-video')[index];

    console.log('----------',videoPlaying);

    if(videoPlaying === index){
      console.log('handleMouseLeave');
      handleMouseLeave(index, video);
      setVideoPlaying(-1);

      return;
    }

    setVideoPlaying(index);

    if (video) {
      setMuted((prevMuted) => !prevMuted); // Toggle mute state
      video.play();
    }
  };

  // Highlighted: Added touch event handlers
  const handleTouchStart = (index) => {
    setPlayingVideoIndex(index);
    const video = document.querySelectorAll('.hover-video')[index];

    if (video) {
      video.currentTime = 0; // Reset video time
      video.play(); // Play the video
    }
  };

  const handleTouchEnd = (index) => {
    const video = document.querySelectorAll('.hover-video')[index];

    video.pause();
    video.currentTime = 0;
    setPlayingVideoIndex(null);
    setMuted(true);
  };

  useEffect(() => {
    const videos = document.querySelectorAll('.hover-video');

    videos.forEach(video => {
      video.muted = muted;
    });
  }, [muted]);

  const handlePrev = () => {
    if (currentSlide > 0) {
      if(window.innerWidth > 800)
        setCurrentSlide(-1);
      else setCurrentSlide(currentSlide - 1);

      setfinalSlide(false);
    }
  };

  const handleNext = () => {
    const maxSlides = thumbnails.length - (window.innerWidth <= 800 ? 1 : 3);

    if(finalSlide){
      return;
    }

    if (currentSlide < maxSlides) {
      if (currentSlide === -1) {
        setCurrentSlide(currentSlide + 2);

        if(window.innerWidth > 800)
          setfinalSlide(true);

        return;
      }

      setCurrentSlide(currentSlide + 1);

      if(!(currentSlide + 1 < maxSlides) ){
        setfinalSlide(true);
      } else setfinalSlide(false);
    }


  };

  useEffect(() => {
    const cardContainer = document.querySelector(".wwu-exp-card");
    const slideWidth = window.innerWidth <= 800 ? 280 : 300 * 3 + 2 * 3; // Adjust slide width based on screen size

    cardContainer.scrollLeft = currentSlide * slideWidth;
  }, [currentSlide]); 

  const thumbnails = ["https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm46m3lp2.webp","https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm46m2037.webp", "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm46m6ls5.webp", "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm46m8c5y.webp", "https://s3.ap-south-1.amazonaws.com/testconvexstorage.kraftshala.com/test/18d4a409-0f1b-479b-bad9-c100958b83c1/events/banner-upload/14i86y70rnm46lylhj.webp"];
  const videoSources = [
    "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/no-auth-uplaod/Report/Video+1+Kirti.mp4",
    "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/no-auth-uplaod/Report/Video+2+Anushka.mp4",
    "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/no-auth-uplaod/Report/Video+3+Praveen.mp4",
    "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/no-auth-uplaod/Report/Video+4+Priya.mp4",
    "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/no-auth-uplaod/Report/Video+5+Akush+Arora.mp4",
  ];

  return (
    <div className="wwu-experience">
      <div className="wwu-title">Experience at Kraftshala</div>
      <StaticImage
        className="wwu-exp-undeline"
        src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y2jyfm2opdx7v.png"
      />
      <div className="wwu-exp-desc">
        Check out what the team has to say about their experience at Kraftshala{" "}
        {inView && <svg
          className="exp-heart-svg mobile-none"
          width="27"
          height="28"
          viewBox="0 0 27 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            className="wwu-exp-hrt"
            d="M2.65184 25.5735C2.15814 19.9783 -0.0142305 14.6812 1.5685 9.01799C2.84439 4.45269 8.26353 -0.352189 13.3741 1.35132C15.0918 1.9239 15.8815 3.96081 16.2213 5.55966C16.3568 6.19734 16.4612 6.97097 16.4713 7.6291C16.4734 7.76977 16.178 9.23316 16.2907 9.3791C16.4028 9.52416 19.246 8.75771 19.5824 8.71243C20.3928 8.60335 21.4689 8.34626 22.1796 8.89299C24.7473 10.8681 26.9761 15.4828 25.3879 18.5458C22.7052 23.7196 15.9824 24.1077 10.9713 24.8235C8.2797 25.2081 5.37613 26.3235 2.65184 26.3235"
            stroke="#F2AF13"
            stroke-width="2"
            stroke-linecap="round"
          />
        </svg>}
        {inView && <svg
          className="exp-heart-svg pc-none"
          width="17"
          height="18"
          viewBox="0 0 27 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            className="wwu-exp-hrt"
            d="M2.65184 25.5735C2.15814 19.9783 -0.0142305 14.6812 1.5685 9.01799C2.84439 4.45269 8.26353 -0.352189 13.3741 1.35132C15.0918 1.9239 15.8815 3.96081 16.2213 5.55966C16.3568 6.19734 16.4612 6.97097 16.4713 7.6291C16.4734 7.76977 16.178 9.23316 16.2907 9.3791C16.4028 9.52416 19.246 8.75771 19.5824 8.71243C20.3928 8.60335 21.4689 8.34626 22.1796 8.89299C24.7473 10.8681 26.9761 15.4828 25.3879 18.5458C22.7052 23.7196 15.9824 24.1077 10.9713 24.8235C8.2797 25.2081 5.37613 26.3235 2.65184 26.3235"
            stroke="#F2AF13"
            stroke-width="2"
            stroke-linecap="round"
          />
        </svg>}
      </div>
      <div className="Watch-here">
        Watch here{" "}
        
        {inView && <svg
          className="watch-arrow-svg mobile-none"
          width="33"
          height="34"
          viewBox="0 0 35 36"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            className="arrow-line"
            d="M1.5 2.40616C11.445 0.467736 19.1817 1.46554 23.8889 11.3506C27.2974 18.5084 27 26.666 27 34.4062"
            stroke="#505050"
            stroke-width="1.5"
            stroke-linecap="round"
          />
          <path
            className="arrow-inventory"
            d="M20 28.4062C22.0833 29.8337 24.5152 31.2657 26.0833 33.2951C26.139 33.3672 26.5947 34.6188 26.8889 34.2951C27.71 33.392 27.9962 31.9155 28.8333 30.934C30.1314 29.4121 31.9538 28.1713 33.5 26.9062"
            stroke="#505050"
            stroke-width="1.5"
            stroke-linecap="round"
          />
        </svg> }
        {inView && <svg
          className="watch-arrow-svg pc-none"
          width="18"
          height="19"
          viewBox="0 0 35 36"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            className="arrow-line"
            d="M1.5 2.40616C11.445 0.467736 19.1817 1.46554 23.8889 11.3506C27.2974 18.5084 27 26.666 27 34.4062"
            stroke="#505050"
            stroke-width="1.5"
            stroke-linecap="round"
          />
          <path
            className="arrow-inventory"
            d="M20 28.4062C22.0833 29.8337 24.5152 31.2657 26.0833 33.2951C26.139 33.3672 26.5947 34.6188 26.8889 34.2951C27.71 33.392 27.9962 31.9155 28.8333 30.934C30.1314 29.4121 31.9538 28.1713 33.5 26.9062"
            stroke="#505050"
            stroke-width="1.5"
            stroke-linecap="round"
          />
        </svg> }
      </div>
      <div className="wwu-exp-card" ref={ref}>
        {thumbnails.map((thumbnail, index) => (
          <div className="trench" key={index}>
            <div className="reel-container">
                
              <video
                className={`hover-video ${playingVideoIndex === index ? 'show' : 'hide'}`}
                width="350"
                height="500"
                onMouseLeave={(e) => handleMouseLeave(index, e.target)}
                // onClick={handleClick}
                onClick={() => handleClick(index)}
                onTouchStart={() => handleTouchStart(index)}
                onTouchEnd={() => handleTouchEnd(index)}
                muted={muted}
                playsInline
              >
                <source src={videoSources[index]} type="video/mp4" />
                  Your browser does not support the video tag.
              </video>
              
              <img
                src={thumbnail}
                alt={`thumbnail-${index}`}
                className={`thumbnail ${playingVideoIndex === index ? 'hide' : 'show'}`}
                onMouseEnter={() => handleMouseEnter(index)}
              />
              
            </div>
          </div>
        ))}
      </div>
      <div className="exp-btn-container">
        <button className="exp-prevBtn" style={{background: `url(${currentSlide <= 0 ? 'https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y3bdzm2t62ep7.png' : 'https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y3bdzm2t6671e.png'})`}} onClick={handlePrev}></button>
        <button className="exp-nxtBtn" style={{background: `url(${finalSlide ? 'https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y3bdzm2t5ro26.png' : 'https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y3bdzm2t601n8.png'})`}} onClick={handleNext}></button>
      </div>
    </div>
  );
};

export default index;
